<template>
    正在跳转。。。。。。
</template>

<script>
    import { getPathVariableAppKey } from '@/utils/tools'
    import { requestDirect } from '@/utils/api'
    export default {

        mounted() {
            const appkey = getPathVariableAppKey()
            requestDirect(appkey).then(res => {
                console.log(res.data)
                window.location.href = res.data
            })
        }
    }
</script>